.container {
  max-width: 1158px;
  display: flex;
  justify-content: space-between;
  padding: 0px 15px;
}

.wrap {
  width: 100%;
  background-color: var(--light-gray);
  padding-top: 20px;
  padding-bottom: 20px;
}

.year {
  text-align: center;
  padding-top: 20px;
}

.infoSection {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.mobNumber {
  text-decoration: none;
  color: var(--black);
  font-family: var(--font-text);
}

.emailLink {
  text-decoration: none;
  color: var(--black);
  font-family: var(--font-text);
}

@media (max-width: 545px) {
  .container {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
}
