.container {
  padding-top: 16px;
  padding-bottom: 16px;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
}

.headerWrap {
  background-color: rgba(209, 200, 184, 0.68);
  width: 100%;
  position: absolute;
  z-index: 2;
}

.routeBtn {
  display: flex;
  gap: 40px;
  align-items: center;
}

.language {
  display: flex;
  gap: 30px;
  align-items: center;
  padding-right: 46px;
}

.radioBtn {
  padding: 0;
}

.leftSide {
  display: flex;
  gap: 117px;
  padding-left: 24px;
}

.leftSide img {
  cursor: pointer;
}
.mobileWrap img {
  cursor: pointer;
}

.navigateBtn {
  position: relative;
  display: flex;
  align-items: center;
}
.navigateBtn.active::after {
  background-color: var(--beige);
  width: 100%;
}

.navigateBtn::after {
  content: ' ';
  display: block;
  position: absolute;
  height: 1px;
  width: 0%;
  transition: width 0.3s ease-in-out;
  z-index: 12;
  bottom: 0;
  left: 0;
}

.navigateBtn:hover {
  color: var(--beige);
}

.radioGroup {
  position: relative;
}
.radioGroup :not(:first-child) ::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 1px;
  background-color: black;
}
.descMob {
  text-decoration: none;
  color: var(--black);
  font-family: var(--font-text);
}

.menuButton {
  display: none;
}

.mobNumber {
  display: none;
}
.mobileWrap {
  display: none;
}

.icon {
  display: none;
}
@media (max-width: 830px) {
  .headerWrap {
    display: none;
  }

  .descMob {
    display: none;
  }
  .navigateBtn::after {
    display: none;
  }
  .icon {
    display: block;
    margin-right: 10px;
  }

  .menuButton {
    display: block;
    position: fixed;
    top: 83px;
    right: 20px;
    background: rgba(0, 0, 0, 0.1);
    border: none;
    font-size: 24px;
    cursor: pointer;
    z-index: 2;
    padding: 10px;
    border-radius: 16px;
    backdrop-filter: blur(1px);
  }

  .menuButton .bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    background-color: var(--beige);
  }

  .headerWrap.active {
    display: block;
    width: 266px;
    right: 0px;
    background-color: var(--light-gray);
    position: fixed;
  }

  .mobileWrap {
    display: block;
    background-color: rgba(209, 200, 184, 0.68);
    padding: 15px 0;
    width: 100%;
    position: absolute;
    z-index: 1;

    text-align: center;
  }
  .mobileWrap img {
    width: 67px;
  }

  .language {
    flex-direction: column-reverse;
    gap: 60px;
    padding: 0;
  }
  .routeBtn {
    flex-direction: column-reverse;
    padding-top: 60px;
    align-items: start;
  }

  .container {
    flex-direction: column-reverse;
    padding-top: 95px;
    padding-bottom: 22px;
  }

  .leftSide {
    flex-direction: column-reverse;
    align-items: center;
    padding: 0;
  }
  .leftSide img {
    width: 100px;
  }
  .mobNumber {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--black);
    font-family: var(--font-text);
  }
  .home::before {
    content: ' ';
    display: block;
    position: absolute;
    height: 1px;
    width: 100%;
    z-index: 12;
    bottom: 0;
    left: 0;
  }
}
