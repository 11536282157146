.catalogPage {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 60px;
  padding-left: 24px;
  padding-right: 24px;
}

.catalogCard {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 14px;
  min-height: 326px;
}
.Wrap {
  text-align: center;
  padding-top: 200px;
  padding-bottom: 120px;
}

.Title {
  font-family: var(--font-text);
}

.catalogCard button {
  padding: 11px 60px;
}

@media (max-width: 929px) {
  .catalogCard img {
    max-width: 300px;
  }
}
@media (max-width: 590px) {
  .Title {
    font-size: 26px;
  }
}
@media (max-width: 500px) {
  .catalogCard img {
    max-width: 335px;
  }
  .Title {
    font-size: 18px;
  }
}
