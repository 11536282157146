.button {
  padding: 11px 78.5px;
  border: 2px solid var(--brown-light);
  border-radius: 13px;
  color: var(--black);
  transition: background-color 0.3s;
  font-family: var(--font-text);
  font-size: var(--font-size-p);
}

.button:hover {
  background-color: var(--brown-light);
  color: var(--gray-light);
}
