.container {
  max-width: 1440px;
  width: 100%;
  height: 100%;
  position: relative;
}

.bannerImg {
  width: 100%;
}

.bannerImg img {
  width: 100%;
}

.bannerText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 920px;
  width: 100%;
  color: var(--gray-light);

  display: flex;
  flex-direction: column;
  align-items: center;
}

.bannerText button {
  color: var(--gray-light);
}

.bannerText h3 {
  max-width: 588px;
  margin: 36px auto 0;
  padding-bottom: 60px;
}

.bannerText .btn {
  padding-top: 60px;
}

.bannerText h1 .highlight {
  display: inline;
}

.mobPostTitle {
  display: none;
}

.mobTitle {
  display: none;
}

.mobText {
  display: none;
}

@media (max-width: 900px) {
  .bannerText h1 {
    font-size: 24px;
    padding-left: 20px;
  }
  .bannerText {
    top: 60%;
  }
  .bannerText h3 {
    padding: 0 30px 40px;
    font-size: 13px;
    text-align: center;
  }
}

@media (max-width: 625px) {
  .container {
    min-height: 600px;
  }
  .bannerImg img {
    display: none;
  }
  .bannerImg {
    background-image: url('../../images/banner.png');
    height: 600px;
    background-position: 36% 50%;
  }
  .bannerText h1 {
    padding-left: 40px;
    padding-right: 40px;
  }
  .bannerText h1 .highlight {
    display: block;
  }
}

@media (max-width: 625px) {
  .descTitle {
    display: none;
  }
  .descText {
    display: none;
  }
  .mobTitle {
    display: block;
    font-size: 18px;
  }
  .mobPostTitle {
    display: block;
    text-align: center;
    font-size: 18px;
    text-wrap: nowrap;
  }
  .bannerText h1 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .mobText {
    display: block;
  }
}

@media (max-width: 400px) {
  .bannerText h1 {
    font-size: 20px;
    padding-left: 20px;
  }
}
