:root {
  --black: #0e0d0c;
  --light-gray: #d1c8b8;
  --beige: #95775a;
  --brown-light: #b37840;
  --gray-light: #e6e3e1;
  --brown-dark: #8c3b2f;
  --deep-brown: #6c1609;

  --plum: #5c1d2b;
  --light-blue: #cbe5fe;
  --wine: #431213;
  --rusty-orange: #bd571e;
  --dark-wine: #3f0402;
  --soft-brown: #a46130;
  --muted-purple: #70464a;

  --cocoa: #773112;
  --dark-red: #631d2d;
  --warm-beige: #af6442;
  --chocolate: #833f2b;
  --orange-brown: #a9511a;

  --font-H1: 'interMedium';
  --font-text: 'interRegular';

  --font-size-h1: 40px;
  --font-size-h2: 24px;
  --font-size-h3: 16px;
  --font-size-p: 15px;
  --font-size-numbers: 48px;

  --desktop: 1440px;
}

@font-face {
  font-family: 'interRegular';
  src: url('/src/fonts/Inter_24pt-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'interMedium';
  src: url('/src/fonts/Inter_28pt-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  margin: 0 auto;
}

h1 {
  font-family: var(--font-H1);
  font-weight: 500;
  font-size: var(--font-size-h1);
  margin: 0;
}

h2,
h3,
p,
span {
  font-family: var(--font-text);
  font-weight: 400;
  margin: 0;
}

h2 {
  font-size: var(--font-size-h2);
}

h3 {
  font-size: var(--font-size-h3);
}

p {
  font-size: var(--font-size-text);
  margin: 0;
}

.numbers {
  font-family: var(--font-regular);
  font-size: var(--font-size-numbers);
}

button {
  all: unset;
  display: inline-block;
  cursor: pointer;
  font-family: var(--font-text);
}

.ant-radio-button-wrapper {
  background: transparent;
  border: transparent;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  content: none;
}

.ant-radio-button-wrapper:first-child {
  border: transparent;
}

.ant-radio-button-wrapper:hover {
  color: white;
}

.ant-radio-button-wrapper:has(:focus-visible) {
  outline: 3px solid white;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: transparent !important;
  border-color: transparent;
}
.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  background: transparent !important;
  border-color: transparent !important;
  color: white !important;
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover::before {
  background-color: transparent !important;
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):first-child {
  border-color: transparent;
}

.ant-radio-group-solid {
  background-color: transparent;
}

.splide__pagination__page.is-active {
  background-color: var(--beige);
}

@media (max-width: 830px) {
  .ant-radio-group-solid {
    background-color: transparent;
    display: flex;
    width: 100%;
    justify-content: center;
  }
}
