.container {
  max-width: 1158px;
  text-align: center;
  padding-top: 60px;
  position: relative;
}

.container::before {
  content: ' ';
  width: 100%;
  position: absolute;
  left: 0px;
  height: 2px;
  background-color: var(--beige);
}

.aboutTitle {
  padding-top: 60px;
}
.aboutSection {
  text-align: start;
  display: flex;
  align-items: center;
  padding-top: 60px;
  padding-right: 20px;
}

.aboutImg {
  width: 100%;
  flex-basis: 50%;
}
.aboutImg img {
  width: 100%;
}

.aboutText {
  display: flex;
  flex-direction: column;
  gap: 27px;
  flex-basis: 50%;
  padding-left: 20px;
}

.text {
  font-family: var(--font-text);
}

@media (max-width: 600px) {
  .aboutImg {
    display: none;
  }
  .aboutText {
    flex-basis: 100%;
    padding: 0 20px;
  }
  .container::before {
    display: none;
  }
  .container {
    padding-top: 0px;
  }
  .aboutSection {
    padding-top: 30px;
  }
}
