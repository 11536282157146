.container {
  max-width: 1140px;
}

.navigate {
  display: flex;
  padding-top: 160px;
  padding-left: 24px;
  max-width: 1440px;
  margin: 0 auto;
  font-family: var(--font-text);
  cursor: pointer;
}

.navigateAfter {
  position: relative;
}

.navigateAfter::after {
  content: ' ';
  display: block;
  position: absolute;
  bottom: -5px;
  left: -2px;
  width: 111%;
  height: 1px;
  background-color: var(--beige);
}

.section {
  display: flex;
  gap: 73px;

  padding: 124px 0;
}

.sectionDesc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
  gap: 20px;
}

.sectionImg {
  max-width: 540px;
  width: 100%;
}

.img {
  width: 100%;
}

.sectionDesc button {
  padding: 13px 11px;
}

.title {
  font-size: 36px;
  font-family: var(--font-text);
}
.preTitle {
  position: relative;
  font-size: 20px;
  font-family: var(--font-text);
}

.preTitle::after {
  content: ' ';
  display: block;
  position: absolute;
  bottom: -5px;
  left: -2px;
  width: 111%;
  height: 1px;
  background-color: var(--beige);
}

/* standart style radio */

.variantSelector {
  display: flex;
  gap: 10px;
}

.variantLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.variantInput {
  display: none;
}

.variantCircle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: inline-block;
}

.variantInput:checked + .variantCircle {
  box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.4);
}

.description {
  font-size: var(--font-size-p);
  font-family: var(--font-text);
}

@media (max-width: 939px) {
  .sectionDesc {
    gap: 10px;
  }

  .title {
    font-size: 30px;
  }

  .preTitle {
    font-size: 18px;
  }
  .variantSelector {
    margin-bottom: 30px;
  }
}

@media (max-width: 650px) {
  .section {
    flex-direction: column;
    align-items: center;
    padding: 64px 21px;
    gap: 10px;
  }

  .sectionDesc {
    align-items: center;
    gap: 20px;
  }
  .preTitle {
    display: none;
  }
  .sectionDesc .title {
    display: none;
  }
  .sectionDesc button {
    margin-top: 40px;
  }
  .variantSelector {
    margin: 0;
  }
  .tabButtons {
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding: 60px 0 20px 0;
  }
  .tabButton {
    font-size: 16px;
    position: relative;
  }
  .tabButton.active {
    color: var(--beige);
  }
  .activeTab::after {
    content: ' ';
    display: block;
    position: absolute;
    bottom: -5px;
    left: 0px;
    width: 100%;
    height: 1px;
    background-color: var(--beige);
  }
}
